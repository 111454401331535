import Configs from "Configs";
const { API_URL } = Configs;
const END_POINT = `${API_URL}Members/`;
const ADMIN_END_POINT = `${API_URL}parentDenylists/`;
const ParentDenylist = {
  get: {
    statusCode: 200,
    url: `${ADMIN_END_POINT}`,
    method: `get`,
    hasToken: true,
    msg: ``,
  },
  getParentDenylist: {
    statusCode: 200,
    url: `${END_POINT}{{id}}/parentDenylists/`,
    method: `get`,
    hasToken: true,
    msg: ``,
  },
  delete: {
    statusCode: 204,
    url: `${END_POINT}{{id}}/parentDenylists/{{fk}}`,
    method: `delete`,
    hasToken: true,
    msg: `Deleted successfully!`,
  },
  addWebsite: {
    statusCode: 200,
    url: `${END_POINT}{{id}}/parentDenylists`,
    method: `post`,
    hasToken: true,
    msg: `Added successfully!`,
  },
};
export default ParentDenylist;
