import Configs from "Configs";
const { API_URL } = Configs;
const END_POINT = `${API_URL}Members/`;
const ADMIN_END_POINT = `${API_URL}alerts/`;
const Alert = {
  get: {
    statusCode: 200,
    url: `${ADMIN_END_POINT}`,
    method: `get`,
    hasToken: true,
    msg: ``,
  },
  getParentAlerts: {
    statusCode: 200,
    url: `${END_POINT}{{id}}/alerts/`,
    method: `get`,
    hasToken: true,
    msg: ``,
  },

  delete: {
    statusCode: 204,
    url: `${END_POINT}{{id}}/alerts/{{fk}}`,
    method: `delete`,
    hasToken: true,
    msg: `Deleted successfully!`,
  },
};
export default Alert;
