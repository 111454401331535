import { Button, Form, Input, Spin } from "antd";
import { AuthContext } from "Contexts/AuthContext";
import React, { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./LoginPage.scss";
import LoginPageDisplay from "./LoginPage.styled";

export default function LoginPage() {
  const [loading, setLoading] = useState(false);
  const { type } = useParams();
  const isAdmin = type === "admin" ? true : false;
  const { login, adminLogin } = useContext(AuthContext);
  const onFinish = async (values) => {
    if (isAdmin) {
      return await adminLogin({ setLoading, ...values });
    }
    await login({ setLoading, ...values });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <LoginPageDisplay>
      <div className="container">
        <div className="inner">
          <div style={{ maxWidth: 300, margin: `100px auto` }}>
            <div
              className="logo"
              style={{ textAlign: `center`, marginBottom: 20 }}
            >
              <img height="100" src="/assets/logo.png" alt="" />
            </div>
            <h2>Login</h2>

            <Spin spinning={loading}>
              <Form
                name="basic"
                initialValues={{
                  email: `btbe4ever@gmail.com`,
                  password: `hello`,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input.Password type="password" placeholder="Password" />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
            {type !== `admin` && (
              <div>
                New to BgWidget? <Link to="/signup">Create an account</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </LoginPageDisplay>
  );
}
