import Blog from "Network/API/Blog";
import { connector } from "Network/DataHandling";

class BlogClass {
  constructor() {
    Object.keys(Blog).map((i) => {
      return (this[i] = async ({ ...rest }) => {
        return await connector({ API_Schema: Blog[i], ...rest });
      });
    });
  }
}

const BlogService = new BlogClass();
export default BlogService;
