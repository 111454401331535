import SecureLS from "secure-ls";

export const ls = new SecureLS({
  encodingType: "rc4",
  isCompression: false,
  encryptionSecret: "4hm3d_id3vz",
});
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isDev = () => {
  return process.env.NODE_ENV === `development` ? true : false;
};
export const isObjectId = (str) => {
  return /^[0-9a-fA-F]{24}$/.test(str);
};
