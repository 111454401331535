import { Button, Card, Form, Input, message, Select, Spin } from "antd";
import { AuthContext } from "Contexts/AuthContext";
import { flag } from "country-emoji";
import MemberService from "Network/DataService/MemberService";
import React, { useContext, useEffect, useState } from "react";
import countries from "./countries.json";
const { Option } = Select;
export default function ChangePhone() {
  const [cache, setCache] = useState({});
  const [editable, setEditable] = useState(false);
  const [form] = Form.useForm();
  const [, forceUpdate] = useState();
  const { user, updateProfile } = useContext(AuthContext);
  const [step, setStep] = useState(0);
  useEffect(() => {
    forceUpdate({});
  }, []);

  const [loading, setLoading] = useState(false);
  const onFinish = async ({ phone, phoneCode, code }) => {
    const { phone: c_phone, phoneCode: c_phoneCode } = user;
    if (step === 0) {
      if (c_phone === phone && c_phoneCode === phoneCode) {
        return message.warning(`Please pick new phone number`);
      }
      await MemberService.sendCode({
        phone,
        phoneCode,
        setLoading,
        onSuccess: async () => {
          setCache({ phone, phoneCode });
          setStep(1);
        },
      });
    }
    if (step === 1) {
      await MemberService.verifyPhone({
        phone: cache.phone,
        phoneCode: cache.phoneCode,
        code,
        setLoading,
        onSuccess: async () => {
          await updateProfile();
          setStep(0);
          setEditable(false);
        },
      });
    }
  };
  return (
    <div className="card">
      <Card
        title="Change Phone"
        bordered={false}
        style={{ minWidth: 300, maxWidth: 600 }}
      >
        <Spin spinning={loading}>
          <Form
            name="changePhone"
            form={form}
            onFinish={onFinish}
            initialValues={{
              phoneCode: user.phoneCode,
              phone: user.phone,
            }}
          >
            {step === 0 && (
              <>
                <Input.Group compact>
                  <Form.Item
                    name="phoneCode"
                    rules={[
                      {
                        required: true,
                        message: "Please choose your Country!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      disabled={!editable}
                      style={{ width: 110 }}
                      optionLabelProp="label"
                      optionFilterProp="country"
                    >
                      {countries.map(
                        (
                          { country_code, country_name, dialling_code },
                          index
                        ) => (
                          <Option
                            key={`country-phone-${index}`}
                            value={dialling_code}
                            country={country_name}
                            label={dialling_code}
                          >
                            <div className="demo-option-label-item">
                              <span role="img" aria-label={country_name}>
                                {flag(country_code)}
                              </span>{" "}
                              {dialling_code}
                            </div>
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Phone!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Phone"
                      disabled={!editable}
                      type="text"
                    />
                  </Form.Item>
                  {editable && (
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        Send Code
                      </Button>
                    </Form.Item>
                  )}
                </Input.Group>
                {!editable && (
                  <Button type="primary" onClick={() => setEditable(true)}>
                    Edit
                  </Button>
                )}
              </>
            )}
            {step === 1 && (
              <>
                <Input.Group compact>
                  <Form.Item
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: "Please input the code you got in the SMS!",
                      },
                    ]}
                  >
                    <Input placeholder="Code" type="text" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Verify
                    </Button>
                  </Form.Item>
                </Input.Group>
              </>
            )}
          </Form>
        </Spin>
      </Card>
    </div>
  );
}
