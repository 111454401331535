import AdminService from "Network/DataService/AdminService";
import MemberService from "Network/DataService/MemberService";
import React, { createContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { sleep } from "Utils/Utils";
export const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  let history = useHistory();
  const [userType, setUserType] = useState(MemberService.getUserType());
  const [isAuthenticated, setIsAuthenticated] = useState(
    MemberService.isLogged()
  );
  const [user, setUser] = useState(MemberService.getUser());
  const handleLogin = async ({ data, successful, type }) => {
    if (successful) {
      const { id: token, user } = data;
      setIsAuthenticated(true);
      setUser(user);
      setUserType(type);
      MemberService.setToken(token);
      MemberService.setUser(user);
      MemberService.setUserType(type);
      await sleep(2000);
      history.push(`/`);
    }
  };
  const login = async (obj) => {
    await MemberService.login({
      ...obj,
      onSuccess: async (response) => {
        await handleLogin({ ...response, type: "parent" });
      },
    });
  };
  const adminLogin = async (obj) => {
    await AdminService.login({
      ...obj,
      onSuccess: async (response) => {
        await handleLogin({ ...response, type: "admin" });
      },
    });
  };
  const signup = async (obj) => {
    await MemberService.signup({
      ...obj,
      onSuccess: async (_) => {
        const { email, password, setLoading } = obj;
        await login({ email, password, setLoading });
      },
    });
  };
  const logout = async () => {
    MemberService.logout();
    setUser(null);
    setIsAuthenticated(false);
  };
  const updateProfile = async () => {
    await MemberService.getMember({
      id: user.id,
      onSuccess: ({ data }) => {
        MemberService.setUser(data);
        setUser(data);
      },
    });
  };
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        adminLogin,
        login,
        signup,
        logout,
        updateProfile,
        user,
        userType,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };
