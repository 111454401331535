import { Button, Form, Input, Modal, Select, Spin } from "antd";
import MemberService from "Network/DataService/MemberService";
import NotificationService from "Network/DataService/NotificationService";
import React, { useEffect, useState } from "react";
const { Option } = Select;

const children = [];
export default function NewItemModal({ modal, setModal, title, getItems }) {
  const [loading, setLoading] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const [form] = Form.useForm();
  const getParents = async () => {
    await MemberService.getParents({
      onSuccess: ({ data }) => {
        data.map((p) => {
          return children.push(
            <Option key={p.id}>
              {p.firstName && p.lastName
                ? `${p.firstName} ${p.lastName}`
                : p.email}
            </Option>
          );
        });
      },
      setLoading: setSelectLoading,
    });
  };
  useEffect(() => {
    getParents();
  }, []);
  const onFinish = async (values) => {
    await NotificationService.newNotification({
      setLoading,
      onSuccess: async () => {
        form.resetFields();
        setModal(false);
        await getItems();
      },
      ...values,
    });
  };

  return (
    <div>
      <Modal title={title} visible={modal} footer={[]}>
        <Spin spinning={loading || selectLoading}>
          <Form name="newItem" onFinish={onFinish} form={form}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input the title!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Details"
              name="details"
              rules={[
                {
                  required: true,
                  message: "Please input the details!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Members" name="members">
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                // onChange={handleChange}
              >
                {children}
              </Select>
            </Form.Item>
            <Form.Item {...{ wrapperCol: { offset: 20 } }}>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
}
