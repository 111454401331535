import { Card, Spin } from "antd";
import Configs from "Configs";
import BlogService from "Network/DataService/BlogService";
import React, { useEffect, useState } from "react";
import "./BlogPage.scss";
import BlogPageDisplay from "./BlogPage.styled";
const { Meta } = Card;

export default function BlogPage() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const getRSS = async () => {
    await BlogService.get({
      onSuccess: ({ data: { result } }) => {
        setArticles(result);
      },
      setLoading,
    });
  };
  useEffect(() => {
    getRSS();
  }, []);
  return (
    <BlogPageDisplay>
      <div className="main">
        <Spin spinning={loading}>
          <div className="articles">
            {articles.map(({ img, title, creator, link }, index) => {
              return (
                <Card
                  onClick={() => window.open(link, "_blank")}
                  key={`blog-${index}`}
                  hoverable
                  style={{ width: 240 }}
                  cover={
                    <img
                      alt="example"
                      src={img ? img : `${Configs.BASE_URL}noimg.png`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = `${Configs.BASE_URL}noimg.png`;
                      }}
                    />
                  }
                >
                  <Meta title={title} description={``} />
                </Card>
              );
            })}
          </div>
        </Spin>
      </div>
    </BlogPageDisplay>
  );
}
