import { AuthProvider } from "Contexts/AuthContext";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "Routing/Routes";
import "./App.less";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
