import styled from "styled-components";
const LayoutComponentDisplay = styled.div`
  min-height: calc(calc(var(--vh, 1vh) * 100) -200px);
  .content {
    margin: 24px 16px 0;
    @media (max-width: 968px) {
      margin: 24px 0;
    }
  }
  #components-layout-demo-responsive .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }

  .site-layout-sub-header-background {
    color: hsla(0, 0%, 100%, 0.65);
    /* background: #001529; */

    display: flex;
    justify-content: space-between;
  }

  .site-layout-background {
    background: rgba(0, 0, 0, 0.5);
    @media (max-width: 968px) {
      padding: 24px 5px;
    }
  }
`;
export default LayoutComponentDisplay;
