import { message as antMessage } from "antd";
import is from "is_js";
import { sleep } from "Utils/Utils";
import { handleAPI } from "./APIHandling";
// ===================================================================================
// dealing with API and data
// ===================================================================================
export const connector = async ({
  API_Schema,
  onSuccess,
  onFail,
  sleepTime,
  setLoading,
  ...rest
}) => {
  const response = await catchErrors({
    func: () => handleAPI({ API_Schema, ...rest }),
    sleepTime,
    setLoading,
  });
  return await handleResponse({ response, onSuccess, onFail, setLoading });
};
export const catchErrors = async ({ func, sleepTime = 0, setLoading }) => {
  typeof setLoading === "function" && setLoading(true);
  try {
    await sleep(sleepTime);
    return await func();
  } catch (err) {
    logError(err);
    return {
      successful: false,
      message: parseErrorMsg(err),
    };
  }
};
export const handleResponse = async ({
  response,
  onSuccess,
  onFail,
  setLoading,
}) => {
  const { successful, message } = response;
  await sleep(1000);
  displayMessage({ successful, message });
  is.function(setLoading) && setLoading(false);
  if (successful === true) {
    return is.function(onSuccess) && onSuccess(response);
  }
  return is.function(onFail) && onFail();
};

// ===================================================================================
// dealing with API and data => end =>
// ===================================================================================

export const logError = (err) => {
  console.log({ err });
};

export const displayMessage = ({ successful, message }) => {
  return successful
    ? message && antMessage.success(message)
    : message.map((msg) => antMessage.error(msg));
};

export const parseErrorMsg = (err) => {
  const fallbackArr = [`Something went wrong!`];
  try {
    const {
      response: {
        data: {
          error: { message, details },
        },
      },
    } = err;
    let errs = [];

    if (details) {
      const { messages } = details;
      Object.keys(messages).map((k) => {
        errs = [...errs, `${k.toLocaleUpperCase()}: ${messages[k][0]}`];
        return errs;
      });
    }
    return errs.length > 0 ? errs : message ? [message] : fallbackArr;
  } catch (error) {
    return fallbackArr;
  }
};
