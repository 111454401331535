import { Button, Form, Input, Modal, Spin } from "antd";
import ParentDenylistService from "Network/DataService/ParentDenylistService";
import React, { useState } from "react";

export default function NewItemModal({
  modal,
  setModal,
  title,
  parentId,
  getItems,
}) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    await ParentDenylistService.addWebsite({
      id: parentId,
      ...values,
      onSuccess: async () => {
        form.resetFields();
        setModal(false);
        await getItems();
      },
      setLoading,
    });
  };

  
  return (
    <div>
      <Modal title={title} visible={modal} footer={[]}>
        <Spin spinning={loading}>
          <Form name="newItem" onFinish={onFinish} form={form}>
            <Form.Item
              label="Website"
              name="hostname"
              rules={[
                {
                  required: true,
                  message: "Please input the website!",
                },
                {
                  pattern: /^(?!:\/\/)([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?$/i,
                  message:
                    "Please enter a valid domain or subdomain, for example: google.com , drive.google.com",
                },
              ]}
              extra="Enter the website domain/subdomain only not full link. For example: google.com, web.faceook.com"
            >
              <Input />
            </Form.Item>

            <Form.Item {...{ wrapperCol: { offset: 20 } }}>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
}
