import Configs from "Configs";
const { API_URL } = Configs;
const END_POINT = `${API_URL}Admins/`;
const Admin = {
  login: {
    statusCode: 200,
    url: `${END_POINT}login?include=user`,
    method: `post`,
    hasToken: false,
    msg: `Logged in successfully`,
  },

  updateProfile: {
    statusCode: 200,
    url: `${END_POINT}{{id}}`,
    method: `post`,
    hasToken: false,
    msg: `test request done  successfully`,
  },
  changePassword: {
    statusCode: 204,
    url: `${END_POINT}change-password`,
    method: `post`,
    hasToken: true,
    msg: `Password changed successfully!`,
  },
};
export default Admin;
