import Admin from "Network/API/Admin";
import { connector } from "Network/DataHandling";

class AdminClass {
  constructor() {
    Object.keys(Admin).map((i) => {
      return (this[i] = async ({ ...rest }) => {
        return await connector({ API_Schema: Admin[i], ...rest });
      });
    });
  }
  
}

const AdminService = new AdminClass();
export default AdminService;
