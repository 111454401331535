import React from "react";
import "./PageHeader.scss";
import PageHeaderDisplay from "./PageHeader.styled";

export default function PageHeader({ title,children }) {
  return (
    <PageHeaderDisplay>
      <h2>{title}</h2>
  <div>{children}</div>
    </PageHeaderDisplay>
  );
}
