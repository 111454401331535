import styled from "styled-components";
const PageHeaderDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 40px;
  }
`;
export default PageHeaderDisplay;
