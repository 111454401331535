import { Button, Card, Form, Input, Spin } from "antd";
import { AuthContext } from "Contexts/AuthContext";
import MemberService from "Network/DataService/MemberService";
import React, { useContext, useEffect, useState } from "react";
export default function ChangeEmail() {
  const [editable, setEditable] = useState(false);
  const [form] = Form.useForm();
  const [, forceUpdate] = useState();
  useEffect(() => {
    forceUpdate({});
  }, []);
  const { user, updateProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const onFinish = async ({ oldPassword, newPassword }) => {
    await MemberService.changePassword({
      oldPassword,
      newPassword,
      setLoading,
      onSuccess: async () => {
        form.resetFields();
        await updateProfile();
      },
    });
  };
  return (
    <div className="card">
      <Card
        title="Change Email"
        bordered={false}
        style={{ minWidth: 300, maxWidth: 600 }}
      >
        <Spin spinning={loading}>
          <Form
            name="changeEmail"
            initialValues={{ email: user.email }}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input disabled={!editable} />
            </Form.Item>
            {editable ? (
              <Form.Item shouldUpdate={true}>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    Change Email
                  </Button>
                )}
              </Form.Item>
            ) : (
              <Button type="primary" onClick={() => setEditable(true)}>
                Edit
              </Button>
            )}
          </Form>
        </Spin>
      </Card>
    </div>
  );
}
