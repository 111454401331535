import Alert from "Network/API/Alert";
import { connector } from "Network/DataHandling";

class AlertClass {
  constructor() {
    Object.keys(Alert).map((i) => {
      return (this[i] = async ({ ...rest }) => {
        return await connector({ API_Schema: Alert[i], ...rest });
      });
    });
  }
}

const AlertService = new AlertClass();
export default AlertService;
