import { Button, Card, DatePicker, Form, Input, Spin } from "antd";
import { AuthContext } from "Contexts/AuthContext";
import moment from "moment";
import MemberService from "Network/DataService/MemberService";
import React, { useContext, useEffect, useState } from "react";
const dateFormat = "YYYY/MM/DD";

export default function EditProfile() {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState();
  const { user, updateProfile } = useContext(AuthContext);
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    forceUpdate({});
  }, []);

  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    const { firstName, lastName } = values;
    const birthdate = values.birthdate.format(dateFormat);
    await MemberService.updateProfile({
      firstName,
      lastName,
      birthdate,
      id: user.id,
      setLoading,
      onSuccess: async () => {
        await updateProfile();
        setEditable(false);
      },
    });
  };
  return (
    <div className="card">
      <Card
        title="Edit Profile"
        bordered={false}
        style={{ minWidth: 300, maxWidth: 600 }}
      >
        <Spin spinning={loading}>
          <Form
            name="profile"
            onFinish={onFinish}
            initialValues={{
              firstName: user.firstName,
              lastName: user.lastName,
              birthdate: user.birthdate
                ? moment(user.birthdate, dateFormat)
                : null,
            }}
            //   onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: "Please input your first name!" },
              ]}
            >
              <Input disabled={!editable} />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: "Please input your last name!" },
              ]}
            >
              <Input disabled={!editable} />
            </Form.Item>

            <Form.Item
              label="BirthDate"
              name="birthdate"
              rules={[
                { required: true, message: "Please input your birthdate!" },
              ]}
            >
              <DatePicker disabled={!editable} />
            </Form.Item>

            {editable ? (
              <Form.Item shouldUpdate={true}>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    Update
                  </Button>
                )}
              </Form.Item>
            ) : (
              <Button type="primary" onClick={() => setEditable(true)}>
                Edit
              </Button>
            )}
          </Form>
        </Spin>
      </Card>
    </div>
  );
}
