import Configs from "Configs";
const { API_URL } = Configs;
const ADMIN_END_POINT = `${API_URL}Notifications/`;
const Notification = {
  get: {
    statusCode: 200,
    url: `${ADMIN_END_POINT}?filter[order]=createdAt%20DESC&filter[include]=memberNotifications`,
    method: `get`,
    hasToken: true,
  },
  getMemberNotifications: {
    statusCode: 200,
    url: `${API_URL}Members/{{id}}/memberNotifications?filter[order]=createdAt%20DESC&filter[include]=notification`,
    method: `get`,
    hasToken: true,
  },
  newNotification: {
    statusCode: 200,
    url: `${ADMIN_END_POINT}`,
    method: `post`,
    hasToken: true,
    msg: `Added Successfully!`,
  },
  delete: {
    statusCode: 200,
    url: `${ADMIN_END_POINT}{{id}}`,
    method: `delete`,
    hasToken: true,
    msg: `Deleted Successfully!`,
  },
  deleteMemberNotifications: {
    statusCode: 200,
    url: `${API_URL}MemberNotifications/{{id}}`,
    method: `delete`,
    hasToken: true,
    msg: ``,
  },
};
export default Notification;
