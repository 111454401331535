import AlertsPage from "Pages/AlertsPage/AlertsPage";
import BlogPage from "Pages/BlogPage/BlogPage";
import DenylistPage from "Pages/DenylistPage/DenylistPage";
import LoginPage from "Pages/LoginPage";
import NotificationsPage from "Pages/NotificationsPage/NotificationsPage";
import ParentsPage from "Pages/ParentsPage/ParentsPage";
import ProfilePage from "Pages/ProfilePage/ProfilePage";
import SignupPage from "Pages/SignupPage/SignupPage";
import React from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
export default function Routes() {
  return (
    <Switch>
      <Route exact path="/signup" component={SignupPage} />
      <Route exact path="/login/" component={LoginPage} />
      <Route exact path="/login/:type" component={LoginPage} />
      {/* <Route exact path="/alerts" component={AlertsPage} /> */}
      <ProtectedRoute
        exact
        path="/parents"
        adminLocked={true}
        component={ParentsPage}
      />

      <ProtectedRoute
        exact
        path="/parents/:itemId"
        adminLocked={true}
        component={ParentsPage}
      />
      <ProtectedRoute
        exact
        path="/notifications"
        component={NotificationsPage}
      />
      <ProtectedRoute
        exact
        path="/notifications/:itemId"
        component={NotificationsPage}
      />
      <ProtectedRoute exact path="/denylist" component={DenylistPage} />
      <ProtectedRoute exact path="/denylist/:itemId" component={DenylistPage} />
      <ProtectedRoute exact path="/alerts" component={AlertsPage} />
      <ProtectedRoute exact path="/alerts/:itemId" component={AlertsPage} />
      <ProtectedRoute exact path="/profile" component={ProfilePage} />
      <ProtectedRoute exact path="/" component={BlogPage} />
    </Switch>
  );
}
