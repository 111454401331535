import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Space, Table } from "antd";
import PageHeader from "Components/PageHeader/PageHeader";
import { AuthContext } from "Contexts/AuthContext";
import NotificationService from "Network/DataService/NotificationService";
import { tablePagination, tableScroll } from "PageBuilder/PageBuildConfigs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import NewItemModal from "./NewItemModal";
import "./NotificationsPage.scss";
import NotificationsPageDisplay from "./NotificationsPage.styled";

export default function NotificationsPage() {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  // tablesearch
  const [searchText, setSearchText] = useState(``);
  const [searchedColumn, setSearchedColumn] = useState(``);

  const { user, userType } = useContext(AuthContext);

  // new item modal
  const [newModal, setNewModal] = useState(false);

  const getItems = useCallback(async () => {
    if (userType === `admin`) {
      await NotificationService.get({
        onSuccess: (res) => {
          let data = res.data;
          data = data.map((d) => {
            return { ...d, key: d.id };
          });
          setItems(data);
        },
        setLoading,
      });
    } else {
      await NotificationService.getMemberNotifications({
        id: user.id,
        onSuccess: ({ data }) => {
          let notifications = data;
          notifications = notifications.filter((n) => n.notification);
          notifications = notifications.map(
            ({ id, read, notification, createdAt }) => {
              return {
                read,
                id,
                title: notification.title,
                details: notification.details,
                createdAt,
              };
            }
          );
          notifications = notifications.map((d) => {
            return { ...d, key: d.id };
          });
          setItems(notifications);
        },
        setLoading,
      });
    }
  }, [user, userType]);
  const deleteItem = async (record) => {
    const { id: itemId } = record;

    await NotificationService.delete({
      id: itemId,
      onSuccess: () => {
        getItems();
      },
      onFail: () => {
        getItems();
      },
      setLoading,
    });
  };
  
  useEffect(() => {
    getItems();
  }, [getItems]);

  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(``);
  };

  let columns = [
    {
      title: "Title",
      dataIndex: "title",
      search: true,
    },
    {
      title: "Details",
      dataIndex: "details",
      search: true,
    },

    {
      title: "Date",
      dataIndex: "createdAt",
      width: 150,
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      render: (text) => (
        <span>{new Date(text).toLocaleDateString("en-US")}</span>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <span>
          <Space>
            {userType === `admin` ? (
              <Popconfirm
                title="Are you sure delete this item?"
                onConfirm={() => {
                  deleteItem(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" type="primary" danger>
                  Delete
                </Button>
              </Popconfirm>
            ) : (
              <>
                <Button size="small" type="primary">
                  Mark as read
                </Button>
              </>
            )}
          </Space>
        </span>
      ),
    },
  ];
  columns = columns.map((c) => {
    c.defaultSortOrder = `descend`;
    if (c.search) {
      c = { ...c, ...getColumnSearchProps(c.dataIndex) };
    }
    return c;
  });
  return (
    <NotificationsPageDisplay>
      <PageHeader title="Notifications">
        {userType === `admin` && (
          <>
            <Button type="primary" onClick={() => setNewModal(true)}>
              Add Notification
            </Button>
            <NewItemModal
              modal={newModal}
              setModal={setNewModal}
              title="New Notification"
              getItems={getItems}
            />
          </>
        )}
      </PageHeader>
      <Table
        style={{ height: `calc(100vh - 220px)` }}
        columns={columns}
        dataSource={items}
        pagination={tablePagination}
        scroll={tableScroll}
        loading={loading}
        bordered
      />
    </NotificationsPageDisplay>
  );
}
