import Notification from "Network/API/Notification";
import { connector } from "Network/DataHandling";

class NotificationClass {
  constructor() {
    Object.keys(Notification).map((i) => {
      return (this[i] = async ({ ...rest }) => {
        return await connector({ API_Schema: Notification[i], ...rest });
      });
    });
  }
}

const NotificationService = new NotificationClass();
export default NotificationService;
