import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { Button, Input, Popconfirm, Space, Table } from "antd";
import PageHeader from "Components/PageHeader/PageHeader";
import MemberService from "Network/DataService/MemberService";
import { tablePagination, tableScroll } from "PageBuilder/PageBuildConfigs";
import React, { useCallback, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { Link, useParams } from "react-router-dom";
import "./ParentsPage.scss";
import ParentsPageDisplay from "./ParentsPage.styled";

export default function ParentsPage() {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  // tablesearch
  const [searchText, setSearchText] = useState(``);
  const [searchedColumn, setSearchedColumn] = useState(``);
  // filter route param
  const { itemId } = useParams();

  const getItems = useCallback(async () => {
    await MemberService.getParents({
      onSuccess: (res) => {
        let data = res.data;
        data = data.map((d) => {
          return { ...d, key: d.id };
        });
        if (itemId) {
          data = data.filter((d) => d.id === itemId);
        }
        setItems(data);
      },
      setLoading,
    });
  }, [itemId]);

  const deleteItem = async (record) => {
    const { id: memberId } = record;
    await MemberService.deleteParent({
      id: memberId,
      onSuccess: () => {
        getItems();
      },
      onFail: () => {
        getItems();
      },
      setLoading,
    });
  };
  useEffect(() => {
    getItems();
  }, [getItems, itemId]);

  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(``);
  };

  let columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      search: true,
      width: 150,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      search: true,
      width: 150,
    },
    {
      title: "Phone Code",
      dataIndex: "phoneCode",
      search: true,
      width: 150,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      width: 150,
      search: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 150,
      search: true,
    },
    {
      title: "Phone Verified",
      dataIndex: "phoneVerified",
      width: 100,
      render: (status) => (
        <span>
          {status ? (
            <CheckCircleOutlined style={{ fontSize: 20, color: `green` }} />
          ) : (
            <InfoCircleOutlined style={{ fontSize: 20, color: `red` }} />
          )}
        </span>
      ),
    },
    {
      title: "Email Verified",
      dataIndex: "emailVerified",
      width: 100,
      render: (status) => (
        <span>
          {status ? (
            <CheckCircleOutlined style={{ fontSize: 20, color: `green` }} />
          ) : (
            <InfoCircleOutlined style={{ fontSize: 20, color: `red` }} />
          )}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      width: 150,
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      render: (text) => (
        <span>{new Date(text).toLocaleDateString("en-US")}</span>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <span>
          <Space>
            <Link to={`/denylist/${record.id}`}>
              <Button size="small" type="dashed" onClick={() => {}}>
                Deny List
              </Button>
            </Link>
            <Link to={`/alerts/${record.id}`}>
              <Button size="small" type="ghost" onClick={() => {}}>
                Alerts
              </Button>
            </Link>

            <Popconfirm
              title="Are you sure delete this item?"
              onConfirm={() => {
                deleteItem(record);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" type="primary" danger onClick={() => {}}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        </span>
      ),
    },
  ];
  columns = columns.map((c) => {
    c.defaultSortOrder = `descend`;
    if (c.search) {
      c = { ...c, ...getColumnSearchProps(c.dataIndex) };
    }
    return c;
  });

  return (
    <ParentsPageDisplay>
      <PageHeader title={itemId ? `Parent Details` : `Parents`}></PageHeader>
      <Table
        columns={columns}
        dataSource={items}
        pagination={tablePagination}
        scroll={tableScroll}
        loading={loading}
        bordered
      />
    </ParentsPageDisplay>
  );
}
