import LayoutComponent from "Components/LayoutComponent";
import { AuthConsumer } from "Contexts/AuthContext";
import React from "react";
import { Redirect, Route } from "react-router-dom";
export default function ProtectedRoute({
  component: Component,
  adminLocked,
  ...rest
}) {
  return (
    <AuthConsumer>
      {({ isAuthenticated, userType }) => {
        return (
          <Route
            render={(props) =>
              isAuthenticated ? (
                adminLocked && userType !== `admin` ? (
                  <Redirect to="/" />
                ) : (
                  <LayoutComponent>
                    <Component {...props} />
                  </LayoutComponent>
                )
              ) : (
                <Redirect to="/login" />
              )
            }
            {...rest}
          />
        );
      }}
    </AuthConsumer>
  );
}
