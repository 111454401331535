import ParentDenylist from "Network/API/ParentDenylist";
import { connector } from "Network/DataHandling";

class ParentDenylistClass {
  constructor() {
    Object.keys(ParentDenylist).map((i) => {
      return (this[i] = async ({ ...rest }) => {
        return await connector({ API_Schema: ParentDenylist[i], ...rest });
      });
    });
  }
}

const ParentDenylistService = new ParentDenylistClass();
export default ParentDenylistService;
