import {
  AlertOutlined,
  BellOutlined,
  ExclamationCircleOutlined,
  GlobalOutlined,
  LogoutOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Layout, Menu, Modal } from "antd";
import { AuthContext } from "Contexts/AuthContext";
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import "./LayoutComponent.scss";
import LayoutComponentDisplay from "./LayoutComponent.styled";
const { confirm } = Modal;

const { Header, Content, Footer, Sider } = Layout;

export default function LayoutComponent({ children }) {
  const { userType, logout } = useContext(AuthContext);
  let { pathname } = useLocation();
  function logoutConfirm() {
    confirm({
      title: "Do you Want to logout?",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure?",
      onOk() {
        console.log("OK");
        logout();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  return (
    <LayoutComponentDisplay>
      <Layout style={{ minHeight: `calc(calc(var(--vh, 1vh) * 100) - 200px)` }}>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            // console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            // console.log(collapsed, type);
          }}
        >
          <div className="logo" />
          <Menu
            style={{ paddingTop: 100 }}
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[pathname]}
          >
            <Menu.Item key="/" icon={<GlobalOutlined />}>
              <Link to="/">Blog</Link>
            </Menu.Item>
            {userType === `admin` && (
              <Menu.Item key="/parents" icon={<UserOutlined />}>
                <Link to="/parents">Parents</Link>
              </Menu.Item>
            )}
            <Menu.Item key="/denylist" icon={<GlobalOutlined />}>
              <Link to="/denylist">Denylist</Link>
            </Menu.Item>
            <Menu.Item key="/alerts" icon={<AlertOutlined />}>
              <Link to="/alerts">Alerts</Link>
            </Menu.Item>
            <Menu.Item key="/notifications" icon={<BellOutlined />}>
              <Link to="/notifications">Notifications</Link>
            </Menu.Item>

            <Menu.Item key="/profile" icon={<UserOutlined />}>
              <Link to="/profile">Profile</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header
            className="site-layout-sub-header-background"
            style={{ padding: 0 }}
          >
            <div className="logo">
              <img src="/assets/logo.png" height="50" alt="" />
            </div>
            <div className="notifications">
              <Menu theme="dark" mode="horizontal">
                <Menu.Item key="logout" onClick={logoutConfirm}>
                  <LogoutOutlined />
                </Menu.Item>
              </Menu>
            </div>
          </Header>
          <Content className="content">
            <div
              className="site-layout-background"
              style={{
                minHeight: `calc(100vh - 160px)`,
              }}
            >
              {children}
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            <small>
              Developed by{" "}
              <a href="https://wb.gy">
                The <b>W</b>e<b>b</b> <b>G</b>u<b>y</b>
              </a>
            </small>
          </Footer>
        </Layout>
      </Layout>
    </LayoutComponentDisplay>
  );
}
