import Member from "Network/API/Member";
import { connector } from "Network/DataHandling";
import { ls } from "Utils/Utils";

const LS_USER_KEY = `SAY_MY_NAME`;
const LS_USER_TYPE_KEY = `PEEKABOO`;
const LS_TOKEN_KEY = `WHERE_IS_MY_MONEY_BITCH`;
class MemberClass {
  constructor() {
    Object.keys(Member).map((i) => {
      return (this[i] = async ({ ...rest }) => {
        return await connector({ API_Schema: Member[i], ...rest });
      });
    });
  }
  setUser = (user) => {
    if (!user) return null;
    ls.set(LS_USER_KEY, user);
  };
  setToken = (token) => {
    if (!token) return null;
    ls.set(LS_TOKEN_KEY, token);
  };
  setUserType = (type) => {
    if (!type) return null;
    ls.set(LS_USER_TYPE_KEY, type);
  };
  getUser = () => {
    const user = ls.get(LS_USER_KEY);
    return user ? user : false;
  };
  getUserType = () => {
    const userType = ls.get(LS_USER_TYPE_KEY);
    return userType ? userType : null;
  };
  getToken = () => {
    const token = ls.get(LS_TOKEN_KEY);
    return token ? token : false;
  };
  isLogged = () => {
    const user = this.getUser();
    const token = this.getToken();
    return user?.id && token ? true : false;
  };
  logout = () => {
    ls.remove(LS_USER_KEY);
    ls.remove(LS_TOKEN_KEY);
    return;
  };
}

const MemberService = new MemberClass();
export default MemberService;
