import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Space, Table } from "antd";
import PageHeader from "Components/PageHeader/PageHeader";
import { AuthContext } from "Contexts/AuthContext";
import ParentDenylistService from "Network/DataService/ParentDenylistService";
import { tablePagination, tableScroll } from "PageBuilder/PageBuildConfigs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { Link, useParams } from "react-router-dom";
import { isObjectId } from "Utils/Utils";
import "./DenylistPage.scss";
import DenylistPageDisplay from "./DenylistPage.styled";
import NewItemModal from "./NewItemModal";

export default function DenylistPage() {
  // fixed state stuff
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  // context stuff
  const { user, userType } = useContext(AuthContext);

  // filter route param
  const { itemId } = useParams();

  // tablesearch
  const [searchText, setSearchText] = useState(``);
  const [searchedColumn, setSearchedColumn] = useState(``);

  // new item modal
  const [newModal, setNewModal] = useState(false);

  // ====================================================================
  // ============================== fixed cruds
  // ====================================================================
  const getItems = useCallback(async () => {
    if (userType === `admin`) {
      if (itemId && isObjectId(itemId)) {
        await ParentDenylistService.getParentDenylist({
          id: itemId,
          onSuccess: (res) => {
            let data = res.data;
            data = data.map((d) => {
              return { ...d, key: d.id };
            });
            setItems(data);
          },
          setLoading,
        });
      } else {
        await ParentDenylistService.get({
          onSuccess: (res) => {
            let data = res.data;
            data = data.map((d) => {
              return { ...d, key: d.id };
            });
            setItems(data);
          },
          setLoading,
        });
      }
    } else {
      const { id } = user;
      await ParentDenylistService.getParentDenylist({
        id,
        onSuccess: (res) => {
          let data = res.data;
          data = data.map((d) => {
            return { ...d, key: d.id };
          });
          setItems(data);
        },
        setLoading,
      });
    }
  }, [itemId, user, userType]);
  const deleteItem = async (record) => {
    const { id: itemId, memberId } = record;
    await ParentDenylistService.delete({
      id: memberId,
      fk: itemId,
      onSuccess: () => {
        getItems();
      },
      onFail: () => {
        getItems();
      },
      setLoading,
    });
  };

  useEffect(() => {
    getItems();
  }, [getItems]);

  // ====================================================================
  // ============================== table search functions
  // ====================================================================
  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(``);
  };

  // ====================================================================
  // ============================== columns stuff
  // ====================================================================
  let columns = [
    {
      title: "Website",
      dataIndex: "hostname",
      search: true,
    },

    {
      title: "Date",
      dataIndex: "createdAt",
      width: 150,
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      render: (text) => (
        <span>{new Date(text).toLocaleDateString("en-US")}</span>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <span>
          <Space>
            {userType === `admin` && (
              <Link to={`/parents/${record.memberId}`}>
                <Button>View Parent</Button>
              </Link>
            )}
            <Popconfirm
              title="Are you sure delete this item?"
              onConfirm={() => {
                deleteItem(record);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        </span>
      ),
    },
  ];
  columns = columns.map((c) => {
    c.defaultSortOrder = `descend`;
    if (c.search) {
      c = { ...c, ...getColumnSearchProps(c.dataIndex) };
    }
    return c;
  });

  return (
    <DenylistPageDisplay>
      <PageHeader title="Denylist">
        {userType !== `admin` && (
          <>
            <Button type="primary" onClick={() => setNewModal(true)}>
              Add Website
            </Button>
            <NewItemModal
              modal={newModal}
              setModal={setNewModal}
              title="New Website"
              parentId={user.id}
              getItems={getItems}
            />
          </>
        )}
      </PageHeader>
      <Table
        style={{ height: `calc(100vh - 220px)` }}
        columns={columns}
        dataSource={items}
        pagination={tablePagination}
        scroll={tableScroll}
        loading={loading}
        bordered
      />
    </DenylistPageDisplay>
  );
}
