import { Button, Form, Input, Spin } from "antd";
import { AuthContext } from "Contexts/AuthContext";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "./SignupPage.scss";
import SignupPageDisplay from "./SignupPage.styled";

export default function SignupPage() {
  const [loading, setLoading] = useState(false);
  const { signup } = useContext(AuthContext);
  const onFinish = async (values) => {
    await signup({ setLoading, ...values });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <SignupPageDisplay>
      <div className="container">
        <div className="inner">
          <div style={{ maxWidth: 300, margin: `100px auto` }}>
            <div
              className="logo"
              style={{ textAlign: `center`, marginBottom: 20 }}
            >
              <img height="100" src="/assets/logo.png" alt="" />
            </div>
            <h2>Signup</h2>
            <Spin spinning={loading}>
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input.Password type="password" placeholder="Password" />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Signup
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
            <div>
              Have an account? <Link to="/login/">Login</Link>
            </div>
          </div>
        </div>
      </div>
    </SignupPageDisplay>
  );
}
