import styled from "styled-components";
const ProfilePageDisplay = styled.div`
  .cardz {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .card {
      margin: 10px;
      width: 30%;
      @media (max-width: 968px) {
        width: 70%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;
export default ProfilePageDisplay;
