import { Button, Card, Form, Input, Spin } from "antd";
import MemberService from "Network/DataService/MemberService";
import React, { useEffect, useState } from "react";

export default function ChangePassword() {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState();
  useEffect(() => {
    forceUpdate({});
  }, []);

  const [loading, setLoading] = useState(false);
  const onFinish = async ({ oldPassword, newPassword }) => {
    await MemberService.changePassword({
      oldPassword,
      newPassword,
      setLoading,
      onSuccess: () => {
        form.resetFields();
      },
    });
  };
  return (
    <div className="card">
      <Card
        title="Change Password"
        bordered={false}
        style={{ minWidth: 300, maxWidth: 600 }}
      >
        <Spin spinning={loading}>
          <Form
            name="changePassword"
            form={form}
            onFinish={onFinish}
            //   onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Current Password"
              name="oldPassword"
              rules={[
                { required: true, message: "Please input your old password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                { required: true, message: "Please input your new password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item shouldUpdate={true}>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length
                  }
                >
                  Change Password
                </Button>
              )}
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </div>
  );
}
