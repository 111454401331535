import styled from "styled-components";
const BlogPageDisplay = styled.div`
  .articles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    > div {
      margin: 14px;
    }
  }
`;
export default BlogPageDisplay;
