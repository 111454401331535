import Configs from "Configs";
const { API_URL } = Configs;
const END_POINT = `${API_URL}Members/`;
const Member = {
  login: {
    statusCode: 200,
    url: `${END_POINT}login?include=user`,
    method: `post`,
    hasToken: false,
    msg: `Logged in successfully`,
  },
  signup: {
    statusCode: 200,
    url: `${END_POINT}`,
    method: `post`,
    hasToken: false,
    msg: `Registered successfully`,
  },
  updateProfile: {
    statusCode: 200,
    url: `${END_POINT}{{id}}`,
    method: `patch`,
    hasToken: true,
    msg: `Updated successfully`,
  },
  getMember: {
    statusCode: 200,
    url: `${END_POINT}{{id}}`,
    method: `get`,
    hasToken: true,
  },
  changePassword: {
    statusCode: 204,
    url: `${END_POINT}change-password`,
    method: `post`,
    hasToken: true,
    msg: `Password changed successfully!`,
  },
  forgetPassword: {
    statusCode: 204,
    url: `${END_POINT}reset`,
    method: `post`,
    hasToken: false,
    msg: `Check you email to reset your password!`,
  },
  resetPassword: {
    statusCode: 204,
    url: `${END_POINT}reset-password`,
    method: `post`,
    hasToken: false,
    msg: `Password changed successfully!`,
  },
  getParents: {
    statusCode: 200,
    url: `${END_POINT}`,
    method: `get`,
    hasToken: true,
  },
  deleteParent: {
    statusCode: 200,
    url: `${END_POINT}{{id}}`,
    method: `delete`,
    hasToken: true,
    msg: `Deleted successfully!`,
  },
  sendCode: {
    statusCode: 200,
    url: `${END_POINT}/send-code`,
    method: `post`,
    hasToken: true,
    msg: `SMS sent successfully, please check you phone!`,
  },
  verifyPhone: {
    statusCode: 200,
    url: `${END_POINT}/verify-phone`,
    method: `post`,
    hasToken: true,
    msg: `Verified successfully!`,
  },
};
export default Member;
