import { AuthContext } from "Contexts/AuthContext";
import React, { useContext } from "react";
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";
import ChangePhone from "./ChangePhone";
import EditProfile from "./EditProfile";
import "./ProfilePage.scss";
import ProfilePageDisplay from "./ProfilePage.styled";

export default function ProfilePage() {
  const { userType } = useContext(AuthContext);
  return (
    <ProfilePageDisplay>
      <div className="cardz">
        {userType !== `admin` && (
          <>
            <EditProfile />
            <ChangeEmail />
            <ChangePhone />
          </>
        )}
        <ChangePassword />
      </div>
    </ProfilePageDisplay>
  );
}
