import Configs from "Configs";
const { API_URL } = Configs;
const END_POINT = `${API_URL}Members/`;
const Blog = {
  get: {
    statusCode: 200,
    url: `${END_POINT}blog`,
    method: `get`,
    hasToken: true,
  },
  
};
export default Blog;
