import Axios from "axios";
import MemberService from "./DataService/MemberService";

export const handleAPI = async ({ API_Schema, ...rest }) => {
  const {
    method = `post`,
    hasToken = true,
    statusCode = 200,
    url,
    msg = ``,
  } = API_Schema;
  let newUrl = rest.id ? url.replace(`{{id}}`, rest.id) : url;
  newUrl = rest.fk ? newUrl.replace(`{{fk}}`, rest.fk) : newUrl;
  delete rest.id;
  delete rest.fk;

  let options = { method, url: newUrl };
  options.headers = hasToken ? { Authorization: MemberService.getToken() } : {};
  options.data = rest ? rest : {};
  const { status, data } = await Axios(options);
  if (status === statusCode) {
    return {
      successful: true,
      message: msg,
      data,
    };
  }
};
